<template>
    <div>
        <h2 @click="hui">回到首页</h2>
        <h1>你无权访问页面</h1>
    </div>
</template>
<script>
export default {
    methods: {
        hui() {
            this.$router.push({ path: '/home' });
        },
    },
};
</script>
<style scoped>
h2 {
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
}
</style>